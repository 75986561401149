import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Container,
  Stack
} from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { extendedFormatQuery } from "./QueryBuilder/ExtendedQueryBuilder";
import "react-querybuilder/dist/query-builder.scss";
import TrackRecordBuilder from "./TrackRecordBuilder";
import ViewTrackRecord from "./ViewTrackRecord";
import ManageTrackRecord from "./ManageTrackRecord/ManageTrackRecord";
import { RangePicker } from "./RangePicker";
import { getConfig } from "../config";

let appConfig = await getConfig();

export function TrackRecordsContainer({ appBusinessUnit, aggData, fieldData }) {
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(null);
  const [correlationIdTracker, setCorrelationIdTracker] = useState(null)
  const [loader, setLoader] = useState(false);
  const [columns, setColumns] = useState([]);
  const [viewDataGrid, setViewDataGrid] = useState(false);
  const [pnlTip, setPnlTip] = useState(null);
  const [expoTip, setExpoTip] = useState(null);
  const [aggregate, setAggregate] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isDateRangeValid, setIsDateRangeValid] = useState(false);
  const [expandHeader, setExpandHeader] = useState(true);
  const [query, setQuery] = useState(
    appBusinessUnit === "Credit" ? appConfig.CREDIT_INITIAL_QUERY : appConfig.PE_INITIAL_QUERY
  )
  const toggleHeader = () => {
    setExpandHeader(!expandHeader)
  };

  useEffect(() => {
    if (appBusinessUnit.length > 0) {
      setViewDataGrid(false);
    }
  }, [appBusinessUnit]);

  return (
    <Container maxWidth={false}>
      <Stack spacing={2} direction='column'>
        <Accordion expanded={expandHeader} sx={{ mt: 2, mb: 2 }}>
          <AccordionSummary 
            expandIcon={<ExpandMoreIcon onClick={toggleHeader} />}
            aria-controls="panel1a-content">
            <Typography variant="h6">Track Records</Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails> 
            <Stack 
              spacing={{ xs: 2, lg: 1 }} 
              direction={{ xs:"column", lg:"row"}} 
              sx={{ width: 1 }}>
              <Stack 
                sx={{ 
                  minWidth: 260,
                  width: { lg: 1/4 } 
                }}
              >
                <ManageTrackRecord
                  setTrackRecordJson={setQuery}
                  setViewDataGrid={setViewDataGrid}
                  appBusinessUnit={appBusinessUnit}
                  trackRecordJson={extendedFormatQuery(query, "JSON")}
                  trackRecordSql={extendedFormatQuery(query, "SQL")}
                  aggregate={aggregate}
                  setAggregate={setAggregate}
                />
              </Stack>
              <Divider
                id="track-record-divider"
                orientation="horizontal"
                flexItem 
                sx={{
                  my: 5, 
                }}
              />
              <Stack 
                spacing={1} sx={{ width: 1 }} 
                alignItems='flex-start'
              >
                <Stack direction='row' alignItems='center'>
                  <Typography variant="caption" sx={{ fontSize: 18 }}>As of date* - &nbsp; </Typography>
                  <RangePicker
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    setIsDateRangeValid={setIsDateRangeValid}
                    appBusinessUnit={appBusinessUnit}
                  />
                </Stack>
                <TrackRecordBuilder
                  query={query}
                  setQuery={setQuery}
                  setLoader={setLoader}
                  setColumns={setColumns}
                  setRows={setRows}
                  setRowCount={setRowCount}
                  setViewDataGrid={setViewDataGrid}
                  setCorrelationIdTracker={setCorrelationIdTracker}
                  appBusinessUnit={appBusinessUnit}
                  aggregateByFields={aggData}
                  fields={fieldData}
                  setPnlTip={setPnlTip}
                  setExpoTip={setExpoTip}
                  startDate={startDate}
                  endDate={endDate}
                  isDateRangeValid={isDateRangeValid}
                  aggregate={aggregate}
                  setAggregate={setAggregate}
                />
              </Stack>
            </Stack>
          </AccordionDetails>
        </Accordion>

        {viewDataGrid && (
          <ViewTrackRecord
            rows={rows} columns={columns} loader={loader} correlationIdTracker={correlationIdTracker}
            rowCount={rowCount} pnlTip={pnlTip} expoTip={expoTip}
          />
        )}
      </Stack>
    </Container>
  );
}
