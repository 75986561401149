import {
    DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector
} from "@mui/x-data-grid";
import LinearProgress from '@mui/material/LinearProgress';
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import InfoIcon from '@mui/icons-material/Info';
import { GetFullDataFile } from "../API/TrackRecord";
import {useEffect, useState} from "react";


function ViewTrackRecord(props) {
  const { rows, columns, loader, correlationIdTracker, rowCount, pnlTip, expoTip } = props;
  const [rowMessage, setRowMessage] = useState("")
  const [paginationModel, setPaginationModel] = useState(JSON.parse(localStorage.getItem('paginationModel')) || {
    pageSize: 10
  });
  

  const pnlCols = ["Profit/Loss (USD)", "Profit/Loss USD", "Adjusted PNL (Book, DTD)", "Profit/Loss (Book)"];
  const expoCols = ["Exposure (USD)", "Exposure USD", "Adjusted Exposure (Book, BOD)", "Exposure (Book)"];


  function createTooltips(cols) {
    if (pnlCols.includes(cols.field) && pnlTip !== null) {
      return pnlTip;
    } else if (expoCols.includes(cols.field) && expoTip !== null) {
      return expoTip;
    }
    return cols.headerName;
  }
  
  useEffect(() => {
    localStorage.setItem('paginationModel', JSON.stringify(paginationModel));
  }, [paginationModel]);

  useEffect(() => {
    if (rowCount > 10000) {
      setRowMessage(
        <div style={{ display: 'flex', alignItems: 'center' }}>
        <InfoIcon style={{ color:"skyblue" }}/>
        <span style={{ marginLeft: '4px', backgroundColor: "powderblue" }}> 
        The first 10,000 rows are displayed. Click Export for the full dataset consisting of {rowCount.toLocaleString()} rows.</span>
        </div>
      );
    } else {
      setRowMessage(null)
    }
  }, [rowCount]);


  async function HandleCustomExport() {
      try {
      const zipContent = await GetFullDataFile(correlationIdTracker)
      // Create a URL for the ZIP blob
      const zipUrl = URL.createObjectURL(zipContent);

      // Create a link and simulate a click to trigger download
      const a = document.createElement('a');
      a.href = zipUrl;
      a.download = `${correlationIdTracker}.zip`;
      a.click();
      } catch (error) {
          console.error("Error downloading file:", error)
      }
  }

  function CustomToolbar() {
      return (
          <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <Button
            variant="text"
            onClick={HandleCustomExport}
            startIcon={<DownloadSharpIcon/>}
            text-align="center"
            size="small"
            style={{ position: "relative", display: "flex"}}
          >
                EXPORT
          </Button>
          </GridToolbarContainer>
      );
  }
 

  return (
      <Box sx={{ height: "100%", width: "100%" }}>
        <div> 
        {rowMessage}
        </div>
        <DataGrid
          density="compact"
          autoHeight
          rows={rows}
          columns={columns.map((col) => ({
            ...col,
            description: createTooltips(col),
          }))}
          getRowId={(row) => row.internalId}
          initialState={{
            pagination: {
              paginationModel: paginationModel
            },
          }}
          loading={loader}
          pageSizeOptions={[10, 25, 50, 75, 100]}
          checkboxSelection
          disableRowSelectionOnClick
          slots={{ toolbar: CustomToolbar, loadingOverlay: LinearProgress }}
          onPaginationModelChange={setPaginationModel}
        />
      </Box>
  );
}

export default ViewTrackRecord;
